/** @format */

@import url("https://fonts.googleapis.com/css2?family=Mulish:wght@400;600;700&display=swap");

body {
  font-family: "Mulish", sans-serif;
  // color: $dark;
  max-width: 100vw;
  max-height: 100vh;
}

html {
  font-size: calc(12px + (15 - 12) * ((100vw - 375px) / (1440 - 375)));
}

a:hover {
  text-decoration: none;
}

.Toastify__toast--success {
  background-color: $app-blue !important;
  font-weight: 700 !important;
  max-height: 20px !important;
  font-size: 0.8rem;
}

.Toastify__toast--error {
  background-color: $app-red !important;
  font-weight: 700 !important;
  font-size: 0.8rem;
}

.Toastify__progress-bar {
  visibility: hidden;
}

.app-bg {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(../images/minimalbg.png);
  background-size: cover;
  background-position: center;
}

.d-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.border-bottom_light {
  border-bottom: 2px solid $app-dark-25;
}

.pointer {
  cursor: pointer;
}

.page-container {
  max-height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-track {
    background-color: lighten($color: $app-blue, $amount: 40);
  }
  &::-webkit-scrollbar-thumb {
    background-color: $app-blue;

    // border: 2px solid lighten($color: $app-blue, $amount: 10);
  }
}

.rounded-lg {
  border-radius: 10px !important;
}

.ads-card {
  border-left: 5px solid $app-blue;
  // border-right: 5px solid $app-green;
  &__text {
    .text {
      background-color: lighten($color: $app-blue, $amount: 10);
      height: 120px;
      border-radius: 6px 6px 0 0;
    }
  }
  &__video {
    .video {
      background-color: lighten($color: $app-blue, $amount: 10);
      height: 120px;
      border-radius: 6px 6px 0 0;
    }
  }
}

.no-break {
  white-space: nowrap;
}

.text-ads__modal {
  height: 70vh;
}

.video-ads__modal {
  height: 25vh;
}

.dropdown-ads {
  color: $dark;
  &:hover {
    color: $white;
    background-color: $app-blue;
  }
}

.border-dark {
  border: 1px solid $app-dark-25;
}

.graph-card {
  overflow-x: auto;
  .graph-container {
    min-width: 500px;
  }
}

.select-tag {
  min-height: 45px;
}

.complaints-container {
  .complaints-card:last-child {
    border-bottom: none !important;
  }
}

.complaints-card {
  border-bottom: 2px solid $app-dark-25;
  .avatar {
    width: 40px;
    height: 40px;
  }
}

@media screen and (max-width: 767.68px) {
  .board-img {
    height: 150px;
  }
}

@media screen and (min-width: 1024px) {
  .complaints-card {
    .name {
      width: 150px;
    }
  }
}

@media screen and (min-width: 1440px) {
  html {
    font-size: 16px;
  }
}

.paginate {
  .li {
    list-style-type: none;
    a {
      cursor: pointer;
    }
  }
}

.icon-edit {
  min-width: 40px;
  width: 40px !important;
  height: 40px !important;
}

tr {
  th {
    white-space: nowrap;
  }
}

img {
  object-fit: cover !important;
}

.img {
  &-1 {
    height: 100px !important;
  }
  &-2 {
    height: 150px !important;
  }
}

.app-checkbox {
  min-width: 20px;
  width: 20px;
  height: 20px;
  border-radius: 3px;
  cursor: pointer;
  border: 1px solid #1877f2;
}
