/** @format */
@import "../partials/partials";

@media screen and (max-width: 767.98px) {
  .sidebar {
    width: 220px;
    position: absolute;
    z-index: 14;
    margin-left: -220px;
  }
  .sidebar-modal {
    z-index: 13;
    background: rgba(0, 0, 0, 0.8);
  }
  .opener {
    animation: opener 0.5s forwards;
    margin-left: 0px;
  }
  .closer {
    animation: closer 0.5s forwards;
  }
  .nav-link {
    font-size: 1.125rem !important;
  }
  @keyframes opener {
    from {
      margin-left: -220px;
    }
    to {
      margin-left: 0px;
    }
  }
  @keyframes closer {
    from {
      margin-left: 0px;
    }
    to {
      margin-left: -220px;
    }
  }
}

.logo {
  @include avatar;
}

.border-transparent {
  border-bottom: 1px solid #ffffff;
}

nav {
  .avatar {
    @include avatar;
  }
}

.sticky {
  position: sticky;
  top: 0;
  z-index: 12;
}

.nav-link {
  transition: all 0.5s;
  color: #ffffff;
  font-size: 1rem;
  font-weight: 500;
  height: 35px;
  border-left: 5px solid transparent;
  .text {
    position: relative;
    top: 1px;
  }
  &:hover {
    color: #ffffff;
  }
}

.active-link {
  // color: $light-green;
  background-color: #ffffff;
  border-left-color: transparentize($color: $app-blue, $amount: 0.2);
  font-weight: 500;
  color: $app-blue;
  &:hover {
    color: $app-blue;
  }
}

@media screen and (min-width: 768px) and (max-width: 992px) {
  .sidebar {
    width: 200px;
  }
}

@media screen and (min-width: 992px) {
  .sidebar {
    width: 250px;
  }
}

@media screen and (min-width: 2000px) {
  .sidebar {
    width: 300px;
  }
}

.page-content {
  max-width: 1300px;
  margin-left: auto;
  margin-right: auto;
}
